import React from 'react';
import styled from 'styled-components';

const SvgStyles = styled.svg`
  fill: var(--gray);

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes logo-animation {
    from {
      transform: translateX(15%) scale(0.9);
    }
  }
  @keyframes slide-down {
    from {
      transform: translate(0, -20%);
    }
  }

  #logo {
    animation: 5s fade-in ease forwards, 2s logo-animation ease forwards;
  }
  #logo-name-line-1,
  #logo-name-line-2,
  #logo-name-line-3 {
    will-change: transform;
    opacity: 0;
    animation: 1.5s fade-in 0.5s ease forwards,
      1.5s slide-down 0.5s ease forwards;
  }
  #logo-name-line-2 {
    animation-delay: 1s;
  }
  #logo-name-line-3 {
    animation-delay: 1.5s;
  }
`;

export default function SplashLogo({ onAnimationEnd }) {
  return (
    <SvgStyles
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="dewan-splash-logo-title"
      aria-describedby="dewan-splash-logo-desc"
      viewBox="0 0 460.52 272.18"
    >
      <title id="dewan-splash-logo-title">DeWan Dental Wellness Logo</title>
      <desc id="dewan-splash-logo-desc">
        An abstract cubism like logo of faces made with lines. Beside a stacked
        vertical block of text, stating 'DeWan Dental Wellness'.
      </desc>
      <g id="logo">
        <path d="M50 23.88s-23.78 35-9.65 97.66C51.52 171.12 80 198.25 80 198.25h-4.7c2.94 3.13 13.8 7.87 13.8 7.87q-4.18-9-7.46-18.35l-.47 5.88s-23.88-22.17-36.6-73.58S50 23.88 50 23.88z" />
        <path d="M.87 185.9c-2.24-20.37.56-24.87.56-24.87A147.41 147.41 0 0012.48 212c7.46 18.17 39.46 54.51 77.59 54.71 25.64.12 69-34.46 78.53-94.5s-2.77-101.52-18.15-133L147.52 45s-4.4-17.59-8.05-24.85c0 0 13.18 5.87 18.31 13.18l-2.93 1.47s28.59 52.49 17.22 133.7c-10.58 75.4-55.43 104-81.79 103.64-38.12-.49-84.22-38.46-89.41-86.24z" />
        <path d="M30.62 250.15s31.27-42.1 37.13-70.49c0 0-3.35 25-11.69 41.69s-25.44 28.8-25.44 28.8zM22.21 207.92a144.67 144.67 0 01-13.29-37.18c-3.75-19.58-2.86-29.17 1.17-35a88 88 0 001.23 35c4.68 19.2 10.89 37.18 10.89 37.18zM28.79 192.62a92.83 92.83 0 01-12.12-32.94c-2.54-17.93-2.9-37.11 1.26-41.69s6.29 11.21 8.31 20a217.29 217.29 0 0011.29 34.19s-5.84-30.88 2.5-39.6a146.65 146.65 0 003.75 53.35S26.24 158 23.36 140.08s-6.67-25-6.26-7.49 3.75 42.51 11.69 60.03zM148.33 84c-39.79-4.91-60.66 19-60.66 19A98.52 98.52 0 01119 91c13.55-2.58 19.84-2 19.84-2a56.49 56.49 0 01-21.47 10.1s17.34-.73 23.86-5.7a23.51 23.51 0 007.1-9.4zM97.24 209.18s38.92 0 61.63-47.75c19.76-41.56 23.64-77.86 20.39-100.55 0 0 12.11 46.8-16.15 100.94-30.11 57.67-65.87 47.36-65.87 47.36z" />
        <path d="M98.18 182.79a60.15 60.15 0 0021.06-16.15l-23.1 5.86V155l-12.21 2.61s2.72-24.57 2.72-40.68-1.37-49-2.72-73.2S107.45 1.47 110.84 0c0 0 3.85 26.06-3 38.94-5.1 9.51-24.68 19.16-35.44 23.78a97 97 0 00-46 42.25s5.49-34.11 56.68-52.83c26.75-9.75 28-39.22 25.94-47.25 0 0-27.29 15.72-21.86 61.85s2.14 74.14 1.23 84.78l11.61-3.31-1.17 20.45s18.89-5.15 31.79-9.71c-.02.05-11.18 21.32-32.44 23.84z" />
      </g>
      <g id="logo-name-line-1">
        <path d="M273.65 96.84h6.24a11.16 11.16 0 015.4 1.25 8.93 8.93 0 013.58 3.55 11.95 11.95 0 010 10.76 9 9 0 01-3.58 3.56 11 11 0 01-5.4 1.26h-6.24zm3 2.73v15h3.2a6.92 6.92 0 003.74-1 6.52 6.52 0 002.41-2.68 9.24 9.24 0 000-7.7 6.5 6.5 0 00-2.41-2.65 7 7 0 00-3.74-1zM299.91 117.22V96.84h12.8v2.62H303v6.21h9.18v2.62H303v6.27h9.76v2.66zM320.34 96.84h3.41l5.13 17.69h-.83l5.19-15.9h2.06l5.22 15.73h-.77l5.68-17.52h3.42l-7.12 20.38h-3.2l-4.4-13.06h.43l-4.49 13.06h-3.19zM353.83 117.22l7-20.38h3.76l6.77 20.38h-3.2l-1.58-4.86h-7.87l-1.71 4.86zm5.61-7.2h6.36l-3.17-9.52zM380 96.84h3.47l9.87 15.8h-.24v-15.8h3v20.38h-3.2l-10.35-16.51h.45v16.51h-3z" />
      </g>
      <g id="logo-name-line-2">
        <path d="M273.65 146.84h6.24a11.16 11.16 0 015.4 1.25 8.93 8.93 0 013.58 3.55 11.95 11.95 0 010 10.76 9 9 0 01-3.58 3.56 11 11 0 01-5.4 1.26h-6.24zm3 2.73v15h3.2a6.92 6.92 0 003.74-1 6.52 6.52 0 002.41-2.68 9.24 9.24 0 000-7.7 6.5 6.5 0 00-2.41-2.65 7 7 0 00-3.74-1zM299.91 167.22v-20.38h12.8v2.62H303v6.21h9.18v2.62H303v6.27h9.76v2.66zM323 146.84h3.47l9.88 15.8h-.24v-15.8h3v20.38h-3.24l-10.35-16.51h.48v16.51h-3zM347.27 149.46v-2.62h16.06v2.62h-7.2l.69-.7v18.46h-3v-18.46l.67.7zM367.78 167.22l7-20.38h3.76l6.76 20.38h-3.2l-1.58-4.86h-7.87l-1.65 4.86zm5.62-7.2h6.35l-3.17-9.52zM397 146.84v17.72h8.78v2.66H394v-20.38z" />
      </g>
      <g onAnimationEnd={onAnimationEnd} id="logo-name-line-3">
        <path d="M270.93 197.84h3.41l5.14 17.69h-.84l5.19-15.9h2.06l5.22 15.73h-.77l5.66-17.52h3.42l-7.12 20.38h-3.2l-4.4-13.06h.44l-4.5 13.06h-3.18zM307.75 218.22v-20.38h12.8v2.62h-9.76v6.21H320v2.62h-9.18v6.27h9.76v2.66zM333.94 197.84v17.72h8.78v2.66H330.9v-20.38zM354.07 197.84v17.72h8.78v2.66H351v-20.38zM371.11 197.84h3.47l9.87 15.8h-.24v-15.8h3v20.38H384l-10.35-16.51h.48v16.51h-3zM398.26 218.22v-20.38h12.8v2.62h-9.76v6.21h9.18v2.62h-9.18v6.27h9.76v2.66zM428.2 218.64a9.59 9.59 0 01-3.83-.75 6.55 6.55 0 01-2.81-2.21 6.35 6.35 0 01-1.11-3.68h3a3.42 3.42 0 00.65 2.17 3.74 3.74 0 001.72 1.26 6.61 6.61 0 002.36.41 6.37 6.37 0 002.32-.4 3.68 3.68 0 001.56-1.08 2.33 2.33 0 00.56-1.53 2.52 2.52 0 00-.72-1.88 5.72 5.72 0 00-2.2-1.21l-3.79-1.41a11.41 11.41 0 01-3.73-2.1 4.33 4.33 0 01-1.28-3.32 4.8 4.8 0 011.89-3.93 8.29 8.29 0 015.31-1.51 7.66 7.66 0 015.05 1.5 5.87 5.87 0 012.06 4.1h-3A3.18 3.18 0 00431 201a4.67 4.67 0 00-2.89-.77 4.52 4.52 0 00-2.84.79 2.41 2.41 0 00-1 2 2.06 2.06 0 00.65 1.59 6 6 0 002 1.07l4.32 1.58a8.66 8.66 0 013.48 2.12 4.72 4.72 0 011.24 3.37 5.41 5.41 0 01-.89 3.06 6 6 0 01-2.63 2.09 10.51 10.51 0 01-4.24.74zM452.79 218.64a9.62 9.62 0 01-3.83-.75A6.2 6.2 0 01445 212h3a3.42 3.42 0 00.65 2.17 3.74 3.74 0 001.72 1.26 6.62 6.62 0 002.37.41 6.44 6.44 0 002.32-.4 3.81 3.81 0 001.56-1.08 2.37 2.37 0 00.55-1.53 2.52 2.52 0 00-.72-1.88 5.57 5.57 0 00-2.2-1.21l-3.79-1.41a11.24 11.24 0 01-3.72-2.1 4.3 4.3 0 01-1.29-3.32 4.8 4.8 0 011.89-3.93 8.3 8.3 0 015.32-1.51 7.65 7.65 0 015 1.5 5.87 5.87 0 012.06 4.1h-3a3.18 3.18 0 00-1.23-2.08 4.66 4.66 0 00-2.88-.77 4.54 4.54 0 00-2.85.79 2.41 2.41 0 00-1 2 2.06 2.06 0 00.65 1.59 6 6 0 002 1.07l4.33 1.58a8.68 8.68 0 013.47 2.12 4.73 4.73 0 011.25 3.37 5.35 5.35 0 01-.9 3.06 6 6 0 01-2.62 2.09 10.59 10.59 0 01-4.15.75z" />
      </g>
    </SvgStyles>
  );
}
